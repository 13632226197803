import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Importar useLocation
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://dskksco.app-palma.teide.app');

function YouNeedMore() {
    const [hasKeys, setHasKeys] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); // Obtener el objeto location

    // Determinar si el path actual es '/cloud' o '/purchase'
    const isSpecialPath = location.pathname === '/cloud' || location.pathname === '/purchase';

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function checkKeys() {
            try {
                const keys = await pb.collection('keys').getFullList({
                    filter: `assignedto="${pb.authStore.model.id}"`,
                    signal,
                });
                const usedKeys = await pb.collection('media').getFullList({
                    filter: `author="${pb.authStore.model.id}"`,
                    signal,
                });
                const usedKeyIds = usedKeys.map(key => key.key);

                const availableKeys = keys.filter(key => !usedKeyIds.includes(key.id));
                setHasKeys(availableKeys.length > 0);
            } catch (err) {
                console.error('Failed to check keys:', err);
            }
        }

        checkKeys();

        return () => {
            controller.abort();
        };
    }, []);

    const handleRedirect = () => {
        // Si el path actual es '/cloud' o '/purchase', navegar a '/view'
        if (isSpecialPath) {
            navigate('/view');
        } else {
            if (hasKeys) {
                navigate('/cloud');
            } else {
                navigate('/purchase');
            }
        }
    };

    return (
        <div className="flex justify-center items-center h-full">
            <button
                onClick={handleRedirect}
                className="button-menu"
            >
                {isSpecialPath ? 'Dashboard' : hasKeys ? 'Upload New' : 'Purchase More'}
            </button>
        </div>
    );
}

export default YouNeedMore;