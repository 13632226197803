import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import Menu from '../components/Menu';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const pb = new PocketBase('https://dskksco.app-palma.teide.app');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        let isMounted = true;

        if (!pb.authStore.isValid) {
            navigate('/login'); // Redirige al login si no está autenticado
            return;
        }

        async function fetchUsername() {
            try {
                const records = await pb.collection('users').getFullList({
                    sort: '-created',
                    signal,
                });
                if (isMounted) {
                    setUsername(records[0].username);
                }
            } catch (err) {
                if (err.name !== 'AbortError' && isMounted) {
                    setError('Failed to fetch username');
                }
            }
        }

        fetchUsername();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [navigate]);

    return (
        <div className="min-h-screen flex flex-col">
            <Menu />
            <div className="flex flex-col justify-center items-center flex-grow bg-gray-100">
                <h1 className="text-4xl font-bold text-gray-800 mb-4">Welcome to Dashboard</h1>
                <p className="text-lg text-gray-600">This is your dashboard.</p>
                {error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                        Username: <span className="text-blue-500">{username}</span>
                    </h2>
                )}
            </div>
        </div>
    );
}

export default Dashboard;
