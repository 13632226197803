import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Menu from './components/Menu';

const pb = new PocketBase('https://dskksco.app-palma.teide.app');

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    useEffect(() => {
        setIsLoggedIn(pb.authStore.isValid);
    }, []);

    if (isLoggedIn === null) {
        return <div>Loading...</div>;
    }

    return isLoggedIn ? <Navigate to="/view" /> : (
        <div className="min-h-screen flex flex-col">
            <Menu />
            <div className="flex flex-col justify-center items-center flex-grow bg-gray-50 p-6">
                <header className="text-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800 mb-2">PylarAI Cloud</h1>
                    <p className="text-base text-gray-600">Upload your tools and AI results here.</p>
                </header>
                <main className="w-full max-w-2xl bg-white p-6 rounded shadow-md">
                    <section className="mb-4">
                        <h2 className="text-xl font-semibold text-gray-800 mb-1">Upload</h2>
                        <p className="text-sm text-gray-600">Upload any file you want to store in the cloud.</p>
                    </section>
                    <section className="mb-4">
                        <h2 className="text-xl font-semibold text-gray-800 mb-1">View</h2>
                        <p className="text-sm text-gray-600">View your uploaded files and tools.</p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold text-gray-800 mb-1">Download</h2>
                        <p className="text-sm text-gray-600">Download your files and tools.</p>
                    </section>
                </main>
                <footer className="mt-8 text-center text-gray-400 text-sm">
                    © 2024 PylarAI Cloud. All rights reserved.
                </footer>
            </div>
        </div>
    );
}

export default App;
