import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';

const pb = new PocketBase('https://dskksco.app-palma.teide.app/');

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (pb.authStore.isValid) {
            navigate('/view');
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await pb.collection('users').authWithPassword(email, password);
            navigate('/view');  // Redirect to view after successful login
        } catch (err) {
            setError('Invalid login credentials');
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            <Menu />
            <div className="flex flex-col justify-center items-center flex-grow bg-gray-100 p-8">
                <form onSubmit={handleLogin} className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6">Login</h2>
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mt-1 block w-full p-2 border rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="password" className="block text-gray-700">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 block w-full p-2 border rounded-md"
                            required
                        />
                    </div>
                    <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-md">Login</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
