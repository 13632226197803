import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';
import { toast } from 'wc-toast';

const pb = new PocketBase('https://dskksco.app-palma.teide.app');

function Upload() {
    const [filename, setFilename] = useState('');
    const [media, setMedia] = useState(null);
    const [availableUploads, setAvailableUploads] = useState(0);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function checkForKey() {
            try {
                const keys = await pb.collection('keys').getFullList({
                    filter: `assignedto="${pb.authStore.model.id}"`,
                    signal,
                });
                const usedKeys = await pb.collection('media').getFullList({
                    filter: `author="${pb.authStore.model.id}"`,
                    signal,
                });
                const usedKeyIds = usedKeys.map(key => key.key);

                const availableKeys = keys.filter(key => !usedKeyIds.includes(key.id));
                setAvailableUploads(availableKeys.length);
            } catch (err) {
                console.log("E002");
                /* if (err.name !== 'AbortError') {
                    toast.error('Failed to check keys. Please try again.');
                } */
            }
        }

        checkForKey();

        return () => {
            controller.abort();
        };
    }, []);

    const handleUpload = async (e) => {
        e.preventDefault();

        try {
            const keys = await pb.collection('keys').getFullList({
                filter: `assignedto="${pb.authStore.model.id}"`,
            });
            const usedKeys = await pb.collection('media').getFullList({
                filter: `author="${pb.authStore.model.id}"`,
            });
            const usedKeyIds = usedKeys.map(key => key.key);

            const availableKey = keys.find(key => !usedKeyIds.includes(key.id));
            if (!availableKey) {
                toast.error('No available keys to use for upload.');
                return;
            }

            const formData = new FormData();
            formData.append('filename', filename);
            formData.append('media', media);
            formData.append('author', pb.authStore.model.id);
            formData.append('key', availableKey.id);

            await pb.collection('media').create(formData);
            toast.success('Upload successful!');
            setFilename('');
            setMedia(null);
            setAvailableUploads(availableUploads - 1); // Decrement the available uploads
        } catch (err) {
            toast.error('Failed to upload. Please try again.');
        }
    };

    return (
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            {availableUploads === 0 ? (
                <div className="bg-yellow-300 p-4 rounded-md mb-4">
                    <p className="text-yellow-900">No available keys. Please <Link to="/purchase" className="underline">buy more keys</Link>.</p>
                </div>
            ) : (
                <>
                    <h2 className="text-2xl font-bold text-gray-800 mb-6">Upload Media
                        <span className="bg-blue-500 text-white text-sm px-2 py-1 rounded-full ml-2 items-center inline-block font-semibold">
                            {availableUploads} available
                        </span>
                    </h2>
                    <form onSubmit={handleUpload}>
                        <div className="mb-4">
                            <label htmlFor="filename" className="block text-gray-700">Filename</label>
                            <input
                                type="text"
                                id="filename"
                                value={filename}
                                onChange={(e) => setFilename(e.target.value)}
                                className="mt-1 block w-full p-2 border rounded-md"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="media" className="block text-gray-700">Media</label>
                            <input
                                type="file"
                                id="media"
                                onChange={(e) => setMedia(e.target.files[0])}
                                className="mt-1 block w-full p-2 border rounded-md"
                                required
                            />
                        </div>
                        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-md">Upload</button>
                    </form>
                </>
            )}
            <wc-toast />
        </div>
    );
}

export default Upload;
