import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Contact from './pages/Contact';
import View from './pages/View';
import Purchase from './pages/Purchase';
import Cloud from './pages/Cloud';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Helmet>
        {/* Umami Analytics */}
      </Helmet>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/view" element={<View />} />
        <Route path="/purchase" element={<Purchase />} />
        <Route path="/cloud" element={<Cloud />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
