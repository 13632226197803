import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function MyButton({ to, children, onClick, isButton = false, className = '' }) {
    const location = useLocation();

    const getLinkClass = (path) => {
        return location.pathname === path ? 'button-menu-path' : 'button-menu';
    };

    if (isButton) {
        return (
            <button onClick={onClick} className={`button-logout ${className}`}>
                {children}
            </button>
        );
    }

    return (
        <Link to={to} className={`mb-2 md:mb-0 md:mr-4 ${getLinkClass(to)} ${className}`}>
            {children}
        </Link>
    );
}

export default MyButton;
