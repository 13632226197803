import React, { useEffect, useState, useMemo } from 'react';
import PocketBase from 'pocketbase';
import Menu from '../components/Menu';

const pb = new PocketBase('https://dskksco.app-palma.teide.app');

const fileColors = {
    /* Web programming */
    'js': 'bg-yellow-200 text-yellow-800',
    'ts': 'bg-blue-200 text-blue-800',
    'html': 'bg-red-200 text-red-800',
    'css': 'bg-green-200 text-green-800',
    'php': 'bg-indigo-200 text-indigo-800',
    'json': 'bg-gray-200 text-gray-800',
    'xml': 'bg-orange-200 text-orange-800',
    /* Text editors */
    'md': 'bg-yellow-200 text-yellow-800',
    'mdx': 'bg-yellow-200 text-yellow-800',
    'txt': 'bg-gray-200 text-gray-800',
    /* Database */
    'sql': 'bg-blue-200 text-blue-800',
    /* Terminal */
    'sh': 'bg-gray-200 text-gray-800',
    'pwsh': 'bg-gray-200 text-gray-800',
    'ps1': 'bg-gray-200 text-gray-800',
    /* OS */
    'py': 'bg-green-200 text-blue-800',
    'c': 'bg-teal-200 text-teal-800',
    'cpp': 'bg-purple-200 text-purple-800',
    'cs': 'bg-blue-200 text-blue-800',
    'rs': 'bg-red-300 text-red-900',
    /* Network */
    'java': 'bg-orange-200 text-orange-800',
    /* Mobile */
    'rb': 'bg-pink-200 text-pink-800',
    'go': 'bg-teal-300 text-teal-900',
    'swift': 'bg-orange-400 text-orange-900',
    'kt': 'bg-purple-300 text-purple-900',
    /* Git */
    'gitattributes': 'bg-orange-200 text-gray-900',
    'gitignore': 'bg-orange-200 text-gray-900',
};

const getFileColorClass = (extension) => {
    return fileColors[extension] || 'bg-purple-300 text-gray-800';
};

const formatDate = (date) => {
    return new Date(date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
};

const formatTime = (date) => {
    return new Date(date).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
};

const groupByDate = (uploads) => {
    return uploads.reduce((acc, upload) => {
        const date = formatDate(upload.created);
        if (!acc[date]) acc[date] = [];
        acc[date].push(upload);
        return acc;
    }, {});
};

const groupByTimeBlocks = (uploads) => {
    return uploads.reduce((acc, upload) => {
        const date = new Date(upload.created);
        const hours = date.getHours();
        const minutes = Math.floor(date.getMinutes() / 5) * 5;
        const timeBlock = `${hours}:${minutes.toString().padStart(2, '0')}`;
        if (!acc[timeBlock]) acc[timeBlock] = [];
        acc[timeBlock].push(upload);
        return acc;
    }, {});
};

function View() {
    const [uploads, setUploads] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUpload, setSelectedUpload] = useState(null);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const fetchUploads = async () => {
            try {
                const records = await pb.collection('media').getFullList({
                    filter: `author="${pb.authStore.model.id}"`,
                    expand: 'key',
                    signal,
                    sort: '-created',
                });
                setUploads(records);
            } catch (err) {
                console.error('E003');
            }
        };

        fetchUploads();

        return () => {
            controller.abort();
        };
    }, []);

    const filteredUploads = useMemo(() => {
        return uploads.filter(upload =>
            upload.filename.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, uploads]);

    const groupedByDate = useMemo(() => groupByDate(filteredUploads), [filteredUploads]);

    const handleClickOutside = (e) => {
        if (e.target.id === 'popup-background') {
            setSelectedUpload(null);
        }
    };

    const renderFilePreview = (upload) => {
        const url = pb.files.getUrl(upload, upload.media);
        const fileExtension = upload.media.split('.').pop().toLowerCase();
        const colorClass = getFileColorClass(fileExtension);

        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
        const videoExtensions = ['mp4', 'webm', 'ogg', 'mkv'];

        if (imageExtensions.includes(fileExtension)) {
            return <img src={url} alt={upload.filename} className="w-full h-32 object-cover rounded-lg border" />;
        } else if (videoExtensions.includes(fileExtension)) {
            return <video src={url} controls className="w-full h-32 object-cover rounded-lg border" />;
        } else {
            return (
                <div className={`w-full h-32 flex items-center justify-center ${colorClass} rounded-lg border p-4`}>
                    <span className="text-gray-700 font-semibold">.{fileExtension}</span>
                </div>
            );
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            <Menu />
            <div className="flex flex-col justify-center items-center flex-grow bg-blue-200 p-4 md:p-8">
                <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-4">My Uploads</h1>
                <div className="flex w-full flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4 mb-4">
                    <input
                        type="text"
                        placeholder="Search uploads..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full md:w-1/2 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                    />
                </div>
                <div className="relative w-full max-w-4xl bg-white p-4 md:p-8 rounded-lg shadow-lg">
                    <div className="absolute transform -translate-x-1/2 w-1 bg-blue-500 h-full left-1/2 rounded-t-full"></div>
                    {Object.keys(groupedByDate).length > 0 ? (
                        Object.keys(groupedByDate).map((date) => (
                            <div key={date} className="px-4 mb-8">
                                <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">{date}</h2>
                                <div className="flex flex-col space-y-4">
                                    {Object.entries(groupByTimeBlocks(groupedByDate[date])).map(([timeBlock, uploads], idx) => (
                                        <div key={timeBlock} className={`bg-[#FFFFFF60] border border-dashed border-blue-300 border-[2px] rounded-2xl p-2 relative flex items-center ${idx % 2 === 0 ? 'justify-start' : 'justify-end'}`}>
                                            <span className={`absolute px-8 ${idx % 2 === 0 ? 'right-12 md:right-60' : 'left-12 md:left-60'} text-gray-500 text-[10px] md:text-[24px]`}>{timeBlock}</span>
                                            <div className={`absolute w-8 h-8 ${getFileColorClass(uploads[0].media.split('.').pop().toLowerCase())} rounded-full flex items-center justify-center transform -translate-x-1/2 left-1/2`}>
                                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                            </div>
                                            <div className={`flex flex-col space-y-4 ${idx % 2 === 0 ? 'items-end pr-2 md:pr-4' : 'items-start pl-2 md:pl-4'} w-1/2 md:w-1/3`}>
                                                {uploads.map((upload) => (
                                                    <div
                                                        key={upload.id}
                                                        className="flex flex-col items-center text-center space-y-2 cursor-pointer w-48 h-48"
                                                        onClick={() => setSelectedUpload(upload)}
                                                    >
                                                        <h3 className="text-[8px] md:text-[14px] font-semibold text-gray-800">{upload.filename}</h3>
                                                        {renderFilePreview(upload)}
                                                        <a
                                                            target="_blank" rel="noreferrer" href={pb.files.getUrl(upload, upload.media)} download={upload.filename} className="text-black font-semibold">
                                                            <div className={`rounded-full ${getFileColorClass(uploads[0].media.split('.').pop().toLowerCase())} flex items-center justify-center ${idx % 2 === 1 ? 'ml-auto' : 'mr-auto'}`}>
                                                                <div className="flex p-2 items-center justify-center">
                                                                    <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 5)"><path d="m6.5 10.5h-4c-1.15200478-.4188699-2-1.70322102-2-3 0-1.65685425 1.34314575-3 3-3 .03335948 0 .06659179.00054449.09968852.00162508.46264217-2.28304993 2.48077946-4.00162508 4.90031148-4.00162508 2.7614237 0 5 2.23857625 5 5 0 .48543539-.0691781.95471338-.1982137 1.39851335.3339576-.25026476.748773-.39851335 1.1982137-.39851335 1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2h-4" /><path d="m6.5 12.5 2 2 2-2" /><path d="m8.5 4.5v10" /></g></svg>
                                                                    <span className="text-[12px] px-2 text-black font-bold">
                                                                        Download
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <div className={`text-blue-400 w-3 h-3 rounded-full flex items-center justify-center ${idx % 2 === 0 ? 'ml-auto' : 'mr-auto'}`}>
                                                            <div className="text-[10px] flex-shrink-0 text-gray-500 text-center">{formatTime(upload.created)}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-600">You have no uploads.</p>
                    )}
                </div>
            </div>

            {selectedUpload && (
                <div id="popup-background" className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center" onClick={handleClickOutside}>
                    <div className="bg-white p-4 md:p-8 rounded-lg shadow-lg max-w-4xl w-full">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">{selectedUpload.filename}</h2>
                        <div className="flex flex-col items-center space-y-4">
                            {selectedUpload.media.split('.').pop().toLowerCase() === 'jpg' || selectedUpload.media.split('.').pop().toLowerCase() === 'jpeg' || selectedUpload.media.split('.').pop().toLowerCase() === 'png' ? (
                                <img src={pb.files.getUrl(selectedUpload, selectedUpload.media)} alt={selectedUpload.filename} className="w-full w-1/2 md:w-1/4 object-cover rounded-lg border" />
                            ) : selectedUpload.media.split('.').pop().toLowerCase() === 'mp4' || selectedUpload.media.split('.').pop().toLowerCase() === 'webm' || selectedUpload.media.split('.').pop().toLowerCase() === 'ogg' || selectedUpload.media.split('.').pop().toLowerCase() === 'mkv' ? (
                                <video src={pb.files.getUrl(selectedUpload, selectedUpload.media)} controls className="w-full h-96 object-cover rounded-lg border" />
                            ) : selectedUpload.media.split('.').pop().toLowerCase() === 'pdf' ? (
                                <iframe src={pb.files.getUrl(selectedUpload, selectedUpload.media)} className="w-full h-96 object-cover rounded-lg border"></iframe>
                            ) : selectedUpload.media.split('.').pop().toLowerCase() === 'py' ? (
                                <div className={`w-full h-96 flex items-center justify-center ${getFileColorClass(selectedUpload.media.split('.').pop().toLowerCase())} rounded-lg border p-4`}>
                                    <pre className="text-gray-700 font-semibold">{selectedUpload.media}</pre>
                                </div>
                            ) : (
                                <div className={`w-full h-96 flex items-center justify-center ${getFileColorClass(selectedUpload.media.split('.').pop().toLowerCase())} rounded-lg border p-4`}>
                                    <span className="text-gray-700 font-semibold">.{selectedUpload.media.split('.').pop().toLowerCase()}</span>
                                </div>
                            )}
                            <a
                                target="_blank" rel="noreferrer" href={pb.files.getUrl(selectedUpload, selectedUpload.media)} download={selectedUpload.filename} className="text-black font-semibold">
                                <div className={`rounded-full ${getFileColorClass(selectedUpload.media.split('.').pop().toLowerCase())} flex items-center justify-center`}>
                                    <div className="flex p-2 items-center justify-center">
                                        <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 5)"><path d="m6.5 10.5h-4c-1.15200478-.4188699-2-1.70322102-2-3 0-1.65685425 1.34314575-3 3-3 .03335948 0 .06659179.00054449.09968852.00162508.46264217-2.28304993 2.48077946-4.00162508 4.90031148-4.00162508 2.7614237 0 5 2.23857625 5 5 0 .48543539-.0691781.95471338-.1982137 1.39851335.3339576-.25026476.748773-.39851335 1.1982137-.39851335 1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2h-4" /><path d="m6.5 12.5 2 2 2-2" /><path d="m8.5 4.5v10" /></g></svg>
                                        <span className="text-[12px] px-2 text-black font-bold">
                                            Download
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="flex items-center justify-center">
                            <button
                                className="flex items-center justify-center absolute top-8 right-10 bg-red-500 text-white rounded-full p-2"
                                onClick={() => setSelectedUpload(null)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default View;
