import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import Menu from '../components/Menu';
import GetKeys from '../components/GetKeys';

const pb = new PocketBase('https://dskksco.app-palma.teide.app');

function Purchase() {
    const [isStaff, setIsStaff] = useState(false);

    useEffect(() => {
        const checkStaff = async () => {
            try {
                const staffMembers = await pb.collection('staff').getFullList({
                    filter: `user="${pb.authStore.model.id}"`,
                });
                setIsStaff(staffMembers.length > 0);
            } catch (err) {
                console.error('Failed to check staff status:', err);
            }
        };

        checkStaff();
    }, []);

    return (
        <div className="min-h-screen flex flex-col">
            <Menu />
            <div className="flex flex-col justify-center items-center flex-grow bg-gray-100 p-8">
                <h1 className="text-4xl font-bold text-gray-800 mb-4">Purchase Keys</h1>
                {isStaff && <GetKeys />}
                <div className="w-full max-w-4xl bg-white p-8 rounded-lg shadow-lg">
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">Basic Pack</h2>
                        <p className="text-gray-600 mb-2">Includes 10 keys</p>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Buy for $10</button>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">Standard Pack</h2>
                        <p className="text-gray-600 mb-2">Includes 50 keys</p>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Buy for $40</button>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">Premium Pack</h2>
                        <p className="text-gray-600 mb-2">Includes 100 keys</p>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Buy for $70</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Purchase;
