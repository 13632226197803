import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import Menu from '../components/Menu';
import { toast } from 'wc-toast';

const pb = new PocketBase('https://dskksco.app-palma.teide.app');

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await pb.collection('contact').create({
                name,
                email,
                message,
            });
            setSubmitted(true);
            toast.success('Thank you for your message!');
        } catch (err) {
            toast.error('Failed to send message. Please try again.');
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            <Menu />
            <div className="flex flex-col justify-center items-center flex-grow bg-gray-100 p-8">
                <h1 className="text-4xl font-bold text-gray-800 mb-4">Contact Us</h1>
                {submitted ? (
                    <p className="text-green-500">Thank you for your message!</p>
                ) : (
                    <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-700">Name</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="mt-1 block w-full p-2 border rounded-md"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="mt-1 block w-full p-2 border rounded-md"
                                required
                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="message" className="block text-gray-700">Message</label>
                            <textarea
                                id="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="mt-1 block w-full p-2 border rounded-md"
                                required
                            />
                        </div>
                        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-md">Send</button>
                    </form>
                )}
            </div>
            <wc-toast />
        </div>
    );
}

export default Contact;
