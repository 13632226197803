import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { toast } from 'wc-toast';
import { v4 as uuidv4 } from 'uuid';

const pb = new PocketBase('https://dskksco.app-palma.teide.app');

function GetKeys() {
    const [loading, setLoading] = useState(false);

    const handleGetKey = async () => {
        setLoading(true);
        try {
            const newKey = {
                key: uuidv4(), // Generate a new UUID as the key
                assignedto: pb.authStore.model.id,
            };
            await pb.collection('keys').create(newKey);
            toast.success('New key added successfully!');
        } catch (err) {
            toast.error('Failed to add key. Please try again.');
        }
        setLoading(false);
    };

    return (
        <div className="bg-yellow-200 p-4 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold text-yellow-900 mb-4">Special Staff Access</h2>
            <p className="text-yellow-800 mb-4">You have special access to add new keys directly.</p>
            <button
                onClick={handleGetKey}
                className="bg-yellow-500 text-white px-4 py-2 rounded-md"
                disabled={loading}
            >
                {loading ? 'Processing...' : 'Get New Key'}
            </button>
            <wc-toast />
        </div>
    );
}

export default GetKeys;
