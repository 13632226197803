import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import MyButton from './buttons/MyButton';
import YouNeedMore from './buttons/YouNeedMore';

const pb = new PocketBase('https://dskksco.app-palma.teide.app/');

function Menu() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoggedIn(pb.authStore.isValid);
    }, []);

    const handleLogout = async () => {
        pb.authStore.clear();
        setIsLoggedIn(false);
        navigate('/login');
    };

    return (
        <div className="bg-black p-4 flex flex-col md:flex-row justify-between items-center shadow-md">
            <div className="flex items-center">
                <h1 className="text-2xl font-bold text-white cursor-pointer mb-2 md:mb-0" onClick={() => navigate('/')}>
                    PylarAI Cloud
                </h1>
                <span className="bg-blue-200 text-black px-2 py-1 rounded-md ml-2 text-sm font-bold">Enterprise</span>
            </div>
            {isLoggedIn ? (
                <>
                    <div className="flex items-center">
                        <YouNeedMore />
                    </div>
                    <div className="flex flex-col md:flex-row items-center">
                        <MyButton to="/view">My Uploads</MyButton>
                        <MyButton to="/dashboard">Dashboard</MyButton>
                        {/* <MyButton to="/cloud">Upload</MyButton> */}
                        <MyButton to="/purchase">Purchase</MyButton>
                        <MyButton isButton onClick={handleLogout}>Logout</MyButton>
                    </div>
                </>
            ) : (
                <div className="flex items-center">
                    <MyButton to="/login">Login</MyButton>
                    <MyButton to="/contact">Contact</MyButton>
                </div>
            )}
        </div>
    );
}

export default Menu;
