import React from 'react';
import Menu from '../components/Menu';
import Upload from '../components/Upload';

function Cloud() {
    return (
        <div className="min-h-screen flex flex-col">
            <Menu />
            <div className="flex flex-col justify-center items-center flex-grow bg-gray-100 p-8">
                <h1 className="text-4xl font-bold text-gray-800 mb-4">Cloud Storage</h1>
                <Upload />
            </div>
        </div>
    );
}

export default Cloud;
